<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.per_page],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="decoratedItems"
    :mobile-breakpoint="0"
    :page="pagination.current_page"
    :server-items-length="pagination.total"
    :loading="loading"
    :item-class="getItemClass"
    :class="{ 'content-loading': loading }"
    class="clickable-rows"
    disable-sort
    @update:page="tableMixin_changePage"
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.title="{ item }">
      <div :style="{ 'max-width': $vuetify.breakpoint.xsOnly ? '250px' : 'unset' }">
        <span :class="{ completed: item.status === 'closed' || item.status === 'rejected' }">
          {{ item.title }}
          <template v-if="item.external_issues_count > 1">
            ({{ item.external_issues_count }})
          </template>
        </span>
        <v-icon v-if="item.description" class="mr-1" small>description</v-icon>
        <v-icon v-if="item.attachments && item.attachments.length" class="mr-1" small
          >attach_file</v-icon
        >
        <CommentCount :count="item.comments_count" />
      </div>
    </template>

    <template v-slot:item.status="{ item }">
      <div class="d-flex">
        <BaseChipSelect
          :value="item.status"
          :disabled="
            (clientView && !$isCurrentUser(+item.received_from_id) && !isSupplierView(item)) ||
            (isSupplierView(item) && !$isCurrentUser(+item.assigned_to))
          "
          :items="issueStatuses"
          max-height="400"
          @change="$emit('update:status', $event, item, item.index)"
        />
        <v-tooltip v-if="item.is_resolved" bottom>
          <template #activator="{ on }">
            <v-icon v-on="on" color="success" class="ml-1">check_circle</v-icon>
          </template>
          <div>
            {{ $t('projects.labels.is_resolved') }}
          </div>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" />
    </template>

    <template v-slot:item.createdBy="{ item }">
      <div v-if="item.author && item.author.personal_data">
        {{ item.author.personal_data.full_name }}
      </div>
      <div>
        {{ item.createdAtHumanized }}
      </div>
    </template>

    <template v-slot:item.planned_implementation_at="{ item }">
      <div>
        <span class="text-no-wrap">
          {{ item.planned_implementation_at }}
        </span>
        <v-tooltip
          v-if="
            item.planned_implementation_at < today &&
            item.status !== 'closed' &&
            item.status !== 'rejected'
          "
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="error">error</v-icon>
          </template>
          <span>
            {{ $t('company_management.general.late') }}
          </span>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.status>
          <div class="d-flex">
            <BaseChipSelect
              :value="item.status"
              :disabled="
                (clientView && !$isCurrentUser(+item.received_from_id) && !isSupplierView(item)) ||
                (isSupplierView(item) && !$isCurrentUser(+item.assigned_to))
              "
              :items="issueStatuses"
              @change="$emit('update:status', $event, item, item.index)"
            />
            <v-tooltip v-if="item.is_resolved" bottom>
              <template #activator="{ on }">
                <v-icon v-on="on" color="success" class="ml-1">check_circle</v-icon>
              </template>
              <div>
                {{ $t('projects.labels.is_resolved') }}
              </div>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.createdBy>
          <div v-if="item.author && item.author.personal_data">
            {{ item.author.personal_data.full_name }}
          </div>
          <div>
            {{ item.createdAtHumanized }}
          </div>
        </template>

        <template v-slot:item.planned_implementation_at>
          <div>
            {{ item.planned_implementation_at || '-' }}
            <v-tooltip
              v-if="
                item.planned_implementation_at < today &&
                item.status !== 'closed' &&
                item.status !== 'rejected'
              "
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="error">error</v-icon>
              </template>
              <span>
                {{ $t('company_management.general.late') }}
              </span>
            </v-tooltip>
          </div>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '../../mixins/table-mixin';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseChipSelect from '../base/BaseChipSelect';
import CommentCount from '../CommentCount';
import { format } from 'date-fns';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectIssueTable',

  components: {
    BaseActionMenu,
    CommentCount,
    BaseChipSelect,
    BaseExpandedTableRow,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    issueStatuses: {
      type: Array,
      required: true,
    },
    clientView: {
      type: Boolean,
      default: false,
    },
    supplierView: {
      type: Boolean,
      default: false,
    },
    singleProject: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    highlightedIssueId: {
      type: [Number, null],
      default: null,
    },
  },

  data() {
    return {
      today: format(new Date(), 'yyyy-MM-dd'),
    };
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),

    headers() {
      const headers = [];
      if (!this.singleProject) {
        headers.push({
          text: this.$t('general.project'),
          hidden: 'xsOnly',
          value: 'projectTitle',
        });
      }
      headers.push({
        text: this.$t('general.issue'),
        value: 'title',
      });
      headers.push({
        text: this.$t('general.status'),
        value: 'status',
        hidden: 'smAndDown',
      });
      if (!this.clientView) {
        headers.push({
          text: this.$t('projects.labels.priority'),
          value: 'priorityLabel',
          hidden: 'smAndDown',
        });
        headers.push({
          text: this.$t('projects.labels.assigned_employee'),
          value: 'assignedUser',
          hidden: 'smAndDown',
        });
        headers.push({
          text: this.$t('general.created_by'),
          value: 'createdBy',
          hidden: 'smAndDown',
        });
      }
      headers.push({
        text: this.$t('projects.labels.planned_implementation'),
        value: 'planned_implementation_at',
        hidden: 'smAndDown',
      });
      if (!this.clientView) {
        headers.push({ value: 'actions' });
      }
      return headers;
    },

    decoratedItems() {
      return this.items.map((i, index) => ({
        ...i,
        index,
        priorityLabel: this.$t(`projects.issue_priorities.${i.priority}`),
        assignedUser: i.assignee?.personal_data?.full_name,
        createdAtHumanized: i.created_at ? this.$timeFromDate(i.created_at) : '',
        projectTitle: i.project_title,
      }));
    },
  },

  methods: {
    isSupplierView(item) {
      for (let i = 0; i < item.project_assignees?.length; i++) {
        if (
          item.project_assignees[i].user_id === this.currentUser.id &&
          item.project_assignees[i].permission === 'supplier'
        ) {
          return true;
        }
      }
      return this.supplierView;
    },

    getItemClass(item) {
      return item.id === this.highlightedIssueId ? 'highlight-background' : '';
    },

    getRowActions(item) {
      const actions = [];
      if (item.external_system_url) {
        actions.push({
          callback: (p) => window.open(p.external_system_url, '_blank'),
          label: this.$t('projects.general.link_to_issue'),
          icon: 'open_in_new',
          inMenu: this.$vuetify.breakpoint.xsOnly,
        });
      }
      if (this.singleProject) {
        actions.push({
          callback: (p) => this.$store.commit('openIssueAsUserStory', p),
          label: this.$t('projects.general.convert_to_story'),
          icon: 'comment',
          inMenu: this.$vuetify.breakpoint.xsOnly,
        });
      }
      if (this.tableMixin_hiddenHeaders.length) {
        actions.push({
          callback: (p) => this.$emit('click:row', p),
          label: this.$t('general.controls.edit'),
          icon: 'edit',
          inMenu: this.$vuetify.breakpoint.xsOnly,
        });
      }
      actions.push({
        callback: (p) => this.$emit('delete', p),
        label: this.$t('general.controls.delete'),
        icon: 'delete',
        inMenu: this.$vuetify.breakpoint.xsOnly,
      });
      return actions;
    },
  },
};
</script>
