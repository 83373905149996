<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchProjectIssues(projectIssueFilterParams)">{{
        $t('projects.tabs.issues')
      }}</v-tab>

      <v-tab-item>
        <div class="d-flex align-center justify-end px-4">
          <ProjectIssueFilter
            v-if="!$isClient()"
            :filter-params="projectIssueFilterParams"
            @change="$router.push({ name: 'projectIssues', query: $event })"
            @reset="resetFilters"
          />
        </div>

        <ProjectIssueTable
          :items="projectIssues"
          :pagination="projectIssuePagination"
          :client-view="$isClient()"
          :supplier-view="currentUserPermissionForEditedIssue === 'supplier'"
          :single-project="!!$route.params.projectId"
          :loading="$store.getters.loading['get:api/project-issues']"
          :issue-statuses="projectIssueStatuses"
          :highlighted-issue-id="+$route.query.issue"
          @click:row="editIssue"
          @delete="deleteProjectIssue"
          @update:page="fetchProjectIssues({ ...projectIssueFilterParams, page: $event })"
          @update:status="onIssueStatusChange"
        />
      </v-tab-item>
    </v-tabs>

    <BasePrimaryActionButton
      v-if="!$isClient()"
      @click="$router.push({ name: 'createProjectIssue' })"
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ProjectIssueTable from '../../components/tables/ProjectIssueTable';
import {
  getDefaultProjectIssueFilterParams,
  getSanitizedProjectIssueFilterParams,
} from '@/store/modules/project-issues-module';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import ProjectIssueFilter from '@/components/filters/ProjectIssueFilter';

export default {
  name: 'ProjectIssues',

  components: {
    ProjectIssueFilter,
    BasePrimaryActionButton,
    ProjectIssueTable,
  },

  props: {
    hideForm: Boolean,
  },

  computed: {
    ...mapState('projectIssues', [
      'projectIssueFilterParams',
      'projectIssues',
      'projectIssuePagination',
    ]),
    ...mapGetters('projectIssues', [
      'projectIssueStatuses',
      'projectIssuePriorities',
      'currentUserPermissionForEditedIssue',
    ]),
  },

  created() {
    // to make sure issues from some project are not being displayed
    this.SET_PROJECT_ISSUES({});
    this.fetchProjectIssues(getSanitizedProjectIssueFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedProjectIssueFilterParams(to.query);
    if (
      JSON.stringify(this.projectIssueFilterParams) !== JSON.stringify(params) &&
      to.name === 'projectIssues'
    ) {
      this.fetchProjectIssues(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('projectIssues', [
      'fetchProjectIssues',
      'updateProjectIssueStatus',
      'deleteProjectIssue',
    ]),
    ...mapMutations('projectIssues', ['SET_PROJECT_ISSUES']),

    editIssue(issue) {
      this.$router.push({ name: 'editProjectIssue', params: { projectIssueId: issue.id } });
    },

    onIssueStatusChange(status, issue) {
      if (status === 'closed') {
        this.$router.push({
          name: 'concludeProjectIssue',
          params: {
            projectIssueId: issue.id,
          },
        });
      } else {
        this.updateProjectIssueStatus({ ...issue, status });
      }
    },

    resetFilters() {
      this.$router.push({
        name: 'projectIssues',
        query: getDefaultProjectIssueFilterParams(),
      });
    },
  },
};
</script>
