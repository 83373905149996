var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"clickable-rows",class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.per_page],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.decoratedItems,"mobile-breakpoint":0,"page":_vm.pagination.current_page,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"item-class":_vm.getItemClass,"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
  var item = ref.item;
return [_c('div',{style:({ 'max-width': _vm.$vuetify.breakpoint.xsOnly ? '250px' : 'unset' })},[_c('span',{class:{ completed: item.status === 'closed' || item.status === 'rejected' }},[_vm._v(" "+_vm._s(item.title)+" "),(item.external_issues_count > 1)?[_vm._v(" ("+_vm._s(item.external_issues_count)+") ")]:_vm._e()],2),(item.description)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("description")]):_vm._e(),(item.attachments && item.attachments.length)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("attach_file")]):_vm._e(),_c('CommentCount',{attrs:{"count":item.comments_count}})],1)]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('BaseChipSelect',{attrs:{"value":item.status,"disabled":(_vm.clientView && !_vm.$isCurrentUser(+item.received_from_id) && !_vm.isSupplierView(item)) ||
          (_vm.isSupplierView(item) && !_vm.$isCurrentUser(+item.assigned_to)),"items":_vm.issueStatuses,"max-height":"400"},on:{"change":function($event){return _vm.$emit('update:status', $event, item, item.index)}}}),(item.is_resolved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":"success"}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('projects.labels.is_resolved'))+" ")])]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"item.createdBy",fn:function(ref){
          var item = ref.item;
return [(item.author && item.author.personal_data)?_c('div',[_vm._v(" "+_vm._s(item.author.personal_data.full_name)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(item.createdAtHumanized)+" ")])]}},{key:"item.planned_implementation_at",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.planned_implementation_at)+" ")]),(
          item.planned_implementation_at < _vm.today &&
          item.status !== 'closed' &&
          item.status !== 'rejected'
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("error")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('company_management.general.late'))+" ")])]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.status",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('BaseChipSelect',{attrs:{"value":item.status,"disabled":(_vm.clientView && !_vm.$isCurrentUser(+item.received_from_id) && !_vm.isSupplierView(item)) ||
              (_vm.isSupplierView(item) && !_vm.$isCurrentUser(+item.assigned_to)),"items":_vm.issueStatuses},on:{"change":function($event){return _vm.$emit('update:status', $event, item, item.index)}}}),(item.is_resolved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":"success"}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('projects.labels.is_resolved'))+" ")])]):_vm._e()],1)]},proxy:true},{key:"item.createdBy",fn:function(){return [(item.author && item.author.personal_data)?_c('div',[_vm._v(" "+_vm._s(item.author.personal_data.full_name)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(item.createdAtHumanized)+" ")])]},proxy:true},{key:"item.planned_implementation_at",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(item.planned_implementation_at || '-')+" "),(
              item.planned_implementation_at < _vm.today &&
              item.status !== 'closed' &&
              item.status !== 'rejected'
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("error")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('company_management.general.late'))+" ")])]):_vm._e()],1)]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }